import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import LazyImage from "../../components/LazyImage";

const Velara = () => {
  const [loading, setLoading] = useState(true);

  // Optionally set loading to false if all images use LazyImage
  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Velara - Personalized Video Outreach for Salespeople</title>
        <meta
          name="description"
          content="Velara provides AI-powered personalized video outreach for salespeople, helping to scale outreach with customized videos from a single recording."
        />
        <meta
          name="keywords"
          content="Velara, personalized video, video outreach, sales, AI-powered outreach"
        />
        <link rel="canonical" href="https://axillio.com/portfolio/velara" />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <LazyImage
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center pt-20 space-y-16 md:space-y-20 md:pt-40 lg:pt-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-4xl md:text-6xl text-center font-gilroyBold">
              Velara
            </h1>
            <h1 className="text-[#303030] text-lg md:text-xl text-center font-semibold">
              "AI-Driven Video Solution for Personalized Messaging"
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center w-full space-y-6">
            <LazyImage
              alt="velara"
              src="/images/velara/velara-1.png"
              className="w-full h-auto"
            />
          </div>

          <div className="flex flex-col items-center justify-center pt-10 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Overview{" "}
            </h1>
            <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[60%] lg:max-w-[55vh]">
              Velara is a dynamic startup offering AI-powered video solutions
              that personalize messages on a one-to-one level.
              <br />
              <br /> The goal was to create an impactful brand identity and
              deliver a seamless product launch that would position Velara as a
              leader in personalized video solutions.
              <br />
              <br /> We partnered with Velara to develop their brand identity,
              design system, digital presence, and launch strategy while
              supporting their growth and social media engagement.
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center px-4 pt-12 space-y-8 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Problems
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Brand Identity Challenge{" "}
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Inconsistent Online Presence{" "}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Limited Social Media Presence{" "}
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Product Launch Strategy{" "}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Post-Launch Growth and Optimization{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-8 pt-12 space-y-4 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              What we solved
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[38vh] py-10 px-5 space-y-8 h-full min-h-[38vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Developed a modern logo, color palette, typography, and
                    design system to create a cohesive brand presence.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[38vh] py-10 px-5 space-y-8 h-full min-h-[38vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Designed a conversion-focused landing page, optimized for
                    mobile and SEO, and integrated tracking tools (Google
                    Analytics, Hotjar).
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[38vh] py-10 px-5 space-y-8 h-full min-h-[38vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Built a tailored content strategy, managed accounts on
                    LinkedIn, Instagram, and Twitter, and ran targeted campaigns
                    to drive awareness.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[38vh] py-10 px-5 space-y-8 h-full min-h-[38vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Created a go-to-market plan, launched ad campaigns, and
                    collaborated on email marketing to generate early leads.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[38vh] py-10 px-5 space-y-8 h-full min-h-[38vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Managed social media campaigns, optimized paid ads, and
                    provided performance insights for ongoing improvements.
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Key Impact Numbers
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    30%
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    increase in customer engagement
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    50k
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    optimized daily active users
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Tools
            </h1>
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Design
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="figma"
                    src="/images/figma.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ai"
                    src="/images/ai.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ae"
                    src="/images/ae.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Development
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="react"
                    src="/images/react.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="js"
                    src="/images/js.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="stripe"
                    src="/images/stripe.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Marketing & Launch
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="monkey"
                    src="/images/monkey.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="bars"
                    src="/images/bars.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="insta"
                    src="/images/insta.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full pt-12 space-y-16 md:space-y-24">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Process
            </h1>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                1. Discovery & Research
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Workshops with Velara to understand the brand, product, and
                market landscape.
              </h1>

              <LazyImage
                alt="velara"
                src="/images/velara/velara-2.png"
                className="w-full h-auto"
              />
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                2. Brand Design{" "}
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Developed a strong visual identity with reusable design
                components.
              </h1>

              <LazyImage
                alt="velara"
                src="/images/velara/velara-3.png"
                className="w-full h-auto"
              />

              <LazyImage
                alt="velara"
                src="/images/velara/velara-4.png"
                className="w-full h-auto"
              />
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                3. Landing Page Design & Development
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Wireframed, prototyped, and built the website with responsive
                design.
              </h1>
              <div className="flex flex-col w-full space-y-8">
                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-5.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <div className="flex flex-col w-full space-y-8">
                    <LazyImage
                      alt="velara"
                      src="/images/velara/velara-6.png"
                      className="w-full h-auto"
                    />
                    <LazyImage
                      alt="velara"
                      src="/images/velara/velara-8.png"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="flex flex-col w-full space-y-8">
                    <LazyImage
                      alt="velara"
                      src="/images/velara/velara-7.png"
                      className="w-full h-auto"
                    />
                    <LazyImage
                      alt="velara"
                      src="/images/velara/velara-9.png"
                      className="w-full h-auto"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-10.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-11.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-12.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-13.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-14.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                4. Dashboard Development
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Wireframed, prototyped, and built the dashboard.
              </h1>

              <div className="flex flex-col w-full space-y-8">
                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-18.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-16.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-17.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="velara"
                    src="/images/velara/velara-15.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                5. Launch & Marketing Strategy
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Executed pre-launch, launch, and post-launch activities to
                ensure a smooth go-to-market experience.
              </h1>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                6. Post-Launch Growth Support
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Managed campaigns and provided ongoing analytics to drive
                growth.
              </h1>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <div className="flex flex-col items-center justify-center pt-16 space-y-8 md:space-y-16">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Key Impact Numbers
                </h1>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        30%
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        increase in customer engagement
                      </h1>
                    </div>
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        50k
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        optimized daily active users
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center w-full pt-20 space-y-8">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Athlantix Founder's Review
                </h1>
                <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                  Velara is a dynamic startup offering AI-powered video
                  solutions that personalize messages on a one-to-one level. The
                  goal was to create an impactful brand identity and deliver a
                  seamless product launch that would position Velara as a leader
                  in personalized video solutions. We partnered with Velara to
                  develop their brand identity, design system, digital presence,
                  and launch strategy while supporting their growth and social
                  media engagement.
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                contact@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <Link
                to={"/explainer"}
                className="text-sm md:text-md text-[#d6d2cd]"
              >
                Portfolio
              </Link>{" "}
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Velara;
