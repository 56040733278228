import { Link } from "react-router-dom";
import React, { useState } from "react";
import ScrollToTop from "../components/ScrollToTop";
import { TextInput } from "@mantine/core";
import { FaUser, FaPhone, FaKey, FaEye, FaEyeSlash } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { Button } from "@mantine/core";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen flex justify-center items-center font-gilroyRegular">
        <div className="bg-[#f0eeeb] flex flex-col items-center justify-center space-y-6 px-8 sm:px-16 md:px-20 py-20 w-[90vw] sm:w-[70vw] md:w-[50vw] lg:w-[40vw] xl:w-[30vw] border border-[#b4b4b4]">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-gilroyBold text-[#303030] pb-4 sm:pb-8 pt-2">
            Log In
          </h1>
          <div className="flex flex-col items-center justify-center w-full pb-6 space-y-4">
            <TextInput
              radius="sm"
              leftSection={<IoMdMail className="text-[#303030] text-xl" />}
              placeholder="Email"
              size="xl"
              className="w-full"
              styles={{
                input: {
                  borderColor: "#908f8d",
                  backgroundColor: "#f0eeeb",
                  color: "#303030",
                },
              }}
            />
            <TextInput
              radius="sm"
              type={passwordVisible ? "text" : "password"} // Conditionally set input type
              leftSection={<FaKey className="text-[#303030] text-xl" />}
              rightSection={
                <div
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer"
                >
                  {passwordVisible ? (
                    <FaEyeSlash className="text-[#303030] text-xl" />
                  ) : (
                    <FaEye className="text-[#303030] text-xl" />
                  )}
                </div>
              }
              placeholder="Password"
              size="xl"
              className="w-full"
              styles={{
                input: {
                  borderColor: "#908f8d",
                  backgroundColor: "#f0eeeb",
                  color: "#303030",
                },
              }}
            />
          </div>
          <Button
            radius="sm"
            variant="filled"
            color="#303030"
            size="xl"
            fullWidth
          >
            Log In
          </Button>

          <div className="flex items-center justify-center w-full space-x-4">
            <div className="flex-grow h-px bg-[#b4b4b4]" />
            <span className="text-[#b4b4b4]">or</span>
            <div className="flex-grow h-px bg-[#b4b4b4]" />
          </div>

          <div className="flex flex-row items-center justify-center pb-6 space-x-10 lg:space-x-24">
            <img
              alt="fb"
              src="/images/Facebooksvg.png"
              className="w-full h-auto "
            />
            <img
              alt="insta"
              src="/images/Instagramsvg.png"
              className="w-full h-auto "
            />
            <img
              alt="google"
              src="/images/Googlesvg.png"
              className="w-full h-auto "
            />
          </div>

          <h1 className="text-[#989b9b] text-sm sm:text-md">
            Dont't have an account?{" "}
            <Link className="text-[#303030] underline" to="/signup">
              Signup
            </Link>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Login;
