import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import LazyImage from "../components/LazyImage";

const ExplainerVideoPage = () => {
  const [loading, setLoading] = useState(true);

  // Optionally set loading to false if all images use LazyImage
  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Explainer Videos | Axillio</title>
        <meta
          name="description"
          content="Discover Axillio's project portfolio through our comprehensive explainer videos. Get detailed insights into our innovative solutions and technical implementations."
        />
        <meta
          name="keywords"
          content="Axillio, explainer videos, project showcase, technical solutions, portfolio, case studies"
        />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <LazyImage
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center pt-20 space-y-16 md:space-y-20 md:pt-40 lg:pt-80">
          <div className="flex flex-col items-center justify-center space-y-2 md:space-y-4">
            <h1 className="text-[#303030]  md:text-xl text-center font-extralight ">
              Explainer Videos & Ad Creatives That Convert
            </h1>
            <h1 className="text-[#303030] text-2xl md:text-4xl text-center font-gilroyBold">
              <span className="underline font-gilroyRegular">
                {" "}
                Bringing your
              </span>{" "}
              Brand Story to Life!{" "}
            </h1>
          </div>{" "}
        </div>
        <div>
          <div className="flex flex-col items-center justify-center pt-16 pb-10 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <Link
                to={"/roam"}
                className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline"
              >
                Roam
              </Link>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they're currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              {" "}
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-1.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                RealBroker
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                RealBroker's collaborative platform transforms real estate operations with centralized property listings and transaction tools. We enable brokers to streamline deals while maintaining full operational control and delivering premium client experiences through transparent processes.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-3.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <Link
                to={"/athlantix"}
                className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline"
              >
                Athlantix
              </Link>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                Athlantix is a comprehensive platform designed to provide
                seamless connection and control for football players,
                coaches, and parents.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-2.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          {/* OBE System Section - Moved up */}
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <Link
                to={"/obe"}
                className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline"
              >
                OBE System
              </Link>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                OBE System provides institutions with objective-based learning frameworks to track Program and Course Learning Outcomes. Our platform facilitates real-time progress monitoring with collaborative tools for educators and students, ensuring alignment across curriculum and assessments.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-6.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                Kypso
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                We transformed Kypso's complex project management ecosystem into an engaging animated story. Our whiteboard-style video showcases how teams seamlessly collaborate across timezones, with smart AI-driven insights that predict project bottlenecks.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-4.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                Acadle
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                -Acadle helps in creating live and video course content,
                organizing it into customized learning paths, and embeding
                courses in your SaaS app.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-5.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                Conductor Quantum
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                Making quantum computing accessible, we developed a 'Science Made Simple' series. Using metaphor-driven animations (subatomic particles as racing cars, qubits as puzzle pieces), we helped non-technical investors grasp their revolutionary architecture.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-9.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                Seven24 AI{" "}
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                For this AI customer service solution, we created a character-driven narrative showing frustrated support teams being rescued by Seven24's always-on assistants. Through dynamic data visualizations, we demonstrated 87% faster resolution times.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-7.png"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-16 space-y-16 md:space-y-20 md:pt-28 lg:pt-40">
            <div className="flex flex-col items-center justify-between px-4 space-y-4 md:flex-row md:items-start md:space-x-64 md:space-y-0 md:px-0">
              <h1 className="text-[#303030] text-2xl md:text-4xl font-gilroyBold underline">
                Pay Pipe{" "}
              </h1>
              <h1 className="text-[#303030] text-lg md:text-2xl text-center md:text-left font-extralight max-w-2xl">
                Our motion graphics explainer helped Pay Pipe stand out in crowded fintech space by visualizing their unique value: instant global payments with military-grade security. We used currency symbols transforming into shield icons and world map animations showing real-time transactions.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-6">
              <LazyImage
                alt="velara"
                src="/images/explainer/explainer-8.png"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24  px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                contact@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <Link
                to={"/explainer"}
                className="text-sm md:text-md text-[#d6d2cd]"
              >
                Portfolio
              </Link>{" "}
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExplainerVideoPage;
