import { useState } from "react";
import Modal from "react-modal";

// Bind modal to your app element
Modal.setAppElement("#root"); // Adjust if your app root has a different id

const VideoLinkModal = ({ isOpen, onClose, onSubmit }) => {
  const [videoLink, setVideoLink] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    // Basic validation for Loom video link
    if (!videoLink.includes("loom.com")) {
      setError("Please enter a valid Loom video link");
      return;
    }

    onSubmit(videoLink);
    setVideoLink("");
    setError("");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "600px",
          padding: "2rem",
          backgroundColor: "#e6e2dd",
          border: "1px solid #8b8986",
          borderRadius: "0px",
        },
      }}
    >
      <div className="flex flex-col space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-gilroyBold text-[#303030]">
            Add Loom Video Link
          </h2>
          <button
            onClick={onClose}
            className="text-[#8b8986] hover:text-[#303030] text-xl"
          >
            ×
          </button>
        </div>

        <div className="flex flex-col space-y-2">
          <input
            type="text"
            placeholder="Paste your Loom video link here"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            className="w-full p-3 border-2 border-[#8b8986] bg-transparent text-[#303030] focus:outline-none focus:border-[#303030]"
          />
          {error && <span className="text-sm text-red-500">{error}</span>}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-6 py-2 border border-[#8b8986] text-[#8b8986] hover:text-[#303030] hover:border-[#303030]"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-6 py-2 bg-[#303030] text-white hover:bg-[#404040]"
          >
            Save Video Link
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VideoLinkModal;
